import { FieldError } from "react-hook-form";

const errorHelpText = (error?: FieldError): string => {
  if (error?.type === "required") {
    return "This field is required.";
  }

  return error?.message || "";
};

const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

const validEmailPattern = {
  value:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "Invalid email address"
};
const validPasswordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

const validNamePattern = {
  value: /^(?=.*[A-Za-z])[A-Za-z\s\-.]+$/,
  message: "At least one letter. Can contain spaces, hyphens, and periods."
};

const validatePhoneNumber = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  // Regular expression to match US/CA phone numbers with optional +1 country code
  const phoneRegex = /^(\+1\s?)?(\d{3})[ -]?(\d{3})[ -]?(\d{4})$/;

  // Test the value against the regex
  return (
    phoneRegex.test(value) ||
    "Invalid phone number. It must be a valid US/CA number with an optional +1 country code."
  );
};

const validateZipcode = (value: string) => {
  const zipcodeRegex = /^[0-9]{5}$/; // Example for a 5-digit US zip code
  return (
    zipcodeRegex.test(value) || "Invalid zip code. It must be 5 digits long."
  );
};

const validateCommaSeparatedList = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  const items = value.split(",").map((item) => item.trim());

  // Check if any item is empty (i.e., consecutive commas or trailing comma)
  const hasEmptyItems = items.some((item) => item === "");

  if (hasEmptyItems) {
    return "Please enter a valid comma-separated list.";
  }

  return true;
};

// Date of birth should not be in the future
export const validateDateOfBirth = (value: string): boolean | string => {
  if (value.length < 10) {
    return false;
  }
  const dateOfBirth = new Date(value);
  const now = new Date();
  return dateOfBirth <= now || "Date of birth cannot be in the future.";
};

// Validates that a date representing a date of birth is at least 18 years ago
export const validateAge =
  (minAge = 18) =>
  (value: string): boolean | string => {
    if (value.length < 10) {
      return true;
    }
    const dateOfBirth = new Date(value);
    const now = new Date();
    const age = now.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = now.getMonth() - dateOfBirth.getMonth();
    const dayDiff = now.getDate() - dateOfBirth.getDate();
    const is18 =
      age > minAge ||
      (age === minAge && monthDiff > 0) ||
      (age === minAge && monthDiff === 0 && dayDiff >= 0);
    return is18 || "You must be at least 18 years old.";
  };

const formatCommaSeparatedList = (value: string): string => {
  const items = value
    .split(",")
    .map((item) => item.trim()) // Trim whitespace from each item
    .filter((item) => item.length > 0); // Remove empty items

  return items.join(", ");
};

export const transformDateToISO = (
  inputValue: string,
  onChange: (...event: any[]) => void
) => {
  // Only attempt the conversion if the input has 10 characters (mm/dd/yyyy)
  if (inputValue.length === 10) {
    const dateParts = inputValue.split("/");
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[1], 10);
      const month = parseInt(dateParts[0], 10) - 1; // Months are zero-indexed in JS Date
      const year = parseInt(dateParts[2], 10);
      const date = new Date(year, month, day);
      // If the date is valid, convert it to ISO8601 format
      if (!isNaN(date.getTime())) {
        onChange(date.toISOString()); // Convert to ISO8601 string
      } else {
        onChange(inputValue); // Handle invalid date
      }
    }
  } else {
    // If the input is not the correct length, just pass the raw value
    onChange(inputValue);
  }
};

const stateOptions = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];
export {
  errorHelpText,
  formatCommaSeparatedList,
  formatPhoneNumber,
  stateOptions,
  validateCommaSeparatedList,
  validatePhoneNumber,
  validateZipcode,
  validEmailPattern,
  validNamePattern,
  validPasswordRegex
};
