// orderpaymentpage.tsx

import { Button, notify } from "@getwellen/valesco";
import { CheckIcon } from "@heroicons/react/24/solid";
import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  SetupIntent
} from "@stripe/stripe-js";
import { LoadingSkeleton } from "components/loading/LoadingSkeleton";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {
  SetupIntentKey,
  StripeOrderIDKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { usePayment } from "contexts/PaymentContext";
import { useCreatePaymentIntentMutation } from "graphql/rails-api"; // Import the mutation hook
import LoadingPage from "pages/LoadingPage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stripePaymentElementOptions } from "utils/stripe";
import { toFriendlyInterval } from "utils/subscription";

const submitButtonText = "Pay now";

type OrderPaymentPageProps = {
  title: string;
  description?: string;
  navigateTo?: string;
};

export const OrderPaymentPage: React.FC<OrderPaymentPageProps> = (props) => {
  // ==============================
  // PROPS
  // ==============================
  const { title, description, navigateTo } = props;

  // ==============================
  // STATE
  // ==============================
  const formRef = useRef<HTMLFormElement>(null);
  const [stripeReady, setStripeReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );

  // ==============================
  // HOOKS
  // ==============================
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const { stripeClientSecret, paymentLoading } = usePayment();

  const {
    isLoading: isOrderLoading,
    order,
    products,
    updateOrder
  } = useOsteoboostOrder();

  const orderStripeOrderID = order[StripeOrderIDKey];

  // ==============================
  // FAKE DATA
  // ==============================
  const isBusy = false;
  const currency = "USD";
  const product = products[0];
  const total = product?.price || 865;
  const totalDisplay = `$${total}`;
  const priceLineItem = {
    description: "OSTEOBOOST FOUNDER’S PROGRAM",
    amountDisplay: totalDisplay
  };

  // ==============================
  // MUTATION HOOKS
  // ==============================
  const [createPaymentIntent, { loading: createLoading }] =
    useCreatePaymentIntentMutation({
      context: { clientName: "rails-api" }
    });

  const isLoading = paymentLoading || isOrderLoading || createLoading;

  // ==============================
  // CALLBACKS
  // ==============================
  const handlePaymentSuccess = useCallback(
    async (stripeSetupIntentId: string, paymentMethodId: string) => {
      // Update the order with the setup intent ID
      updateOrder(SetupIntentKey, stripeSetupIntentId, async () => {
        // Call the createPaymentIntent mutation
        try {
          const { data } = await createPaymentIntent({
            variables: {
              attributes: {
                paymentMethodId: paymentMethodId
              }
            }
          });

          if (data?.createPaymentIntent.success) {
            notify.success("Payment was successful!");

            // Navigate to the specified route if provided
            if (navigateTo) {
              navigate(navigateTo, { replace: true });
            }
          } else {
            data?.createPaymentIntent.errors.forEach((error: string) =>
              notify.error(error)
            );
          }
        } catch (error) {
          console.error(
            "OrderPaymentPage - createPaymentIntent mutation error:",
            error
          );
          notify.error(
            "An unexpected error occurred while processing your payment. Please try again."
          );
        } finally {
          setIsSubmitting(false);
        }
      });
    },
    [createPaymentIntent, navigate, navigateTo, updateOrder]
  );

  const onSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (!stripe || !elements) return;

      setIsSubmitting(true);

      try {
        const result = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url: window.location.href
          }
        });

        if (result.setupIntent?.status === "succeeded") {
          // Pass both setupIntent.id and payment_method_id to handlePaymentSuccess
          handlePaymentSuccess(
            result.setupIntent.id,
            result.setupIntent.payment_method as string
          );
        } else {
          if (result.error?.type === "card_error") {
            notify.error(result?.error?.message || "Payment failure");
          } else if (result.error?.type === "invalid_request_error") {
            notify.error("Internal server error");
            window.location.reload();
          } else {
            notify.error("Payment was not successful. Please try again.");
          }
          setIsSubmitting(false);
        }
      } catch (error) {
        notify.error("Internal server error");
        setIsSubmitting(false);
      }
    },
    [stripe, elements, handlePaymentSuccess]
  );

  const onSubmitPaymentRequest = useCallback(
    async (setupIntent: SetupIntent) => {
      setIsSubmitting(true);
      // Pass both setupIntent.id and payment_method_id to handlePaymentSuccess
      handlePaymentSuccess(
        setupIntent.id,
        setupIntent.payment_method as string
      );
    },
    [handlePaymentSuccess]
  );

  // ==============================
  // EFFECTS
  // ==============================

  // Skip over the payment page if we already have an order.stripeOrderID
  useEffect(() => {
    if (orderStripeOrderID && orderStripeOrderID.length && navigateTo) {
      navigate(navigateTo, { replace: true });
    }
  }, [orderStripeOrderID, navigateTo, navigate]);

  useEffect(() => {
    if (!stripe || paymentRequest || typeof total !== "number" || isLoading)
      return;

    const req = stripe.paymentRequest({
      country: "US",
      currency: currency.toLowerCase(),
      requestPayerName: true,
      total: {
        label: "Total due today",
        amount: Math.round(total * 100)
      }
    });

    req.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(req);
      }
    });
  }, [stripe, total, paymentRequest, isLoading, currency]);

  useEffect(() => {
    if (!paymentRequest || !stripe || !stripeClientSecret || isLoading) return;

    async function handlePaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
      if (!stripe) return;

      const { setupIntent, error: confirmError } =
        await stripe.confirmCardSetup(
          stripeClientSecret as string,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        ev.complete("fail");
        notify.error(
          confirmError.message || "Payment method confirmation failed."
        );
        setIsSubmitting(false);
      } else {
        onSubmitPaymentRequest(setupIntent);
        ev.complete("success");
      }
    }

    async function handleCancel() {
      setIsSubmitting(false);
    }

    paymentRequest.on("cancel", handleCancel);
    paymentRequest.on("paymentmethod", handlePaymentMethod);

    return () => {
      paymentRequest.off("paymentmethod", handlePaymentMethod);
      paymentRequest.off("cancel", handleCancel);
    };
  }, [
    paymentRequest,
    stripe,
    stripeClientSecret,
    isLoading,
    onSubmitPaymentRequest
  ]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="flex justify-center px-6 pt-2 pb-safe md:px-12 md:pb-12 md:pt-1">
      <div className="-mb-16 -mt-2 flex w-full flex-col pb-safe sm:my-0">
        <h1 className="mb-4 font-display text-4xl">{title}</h1>
        {description && <p className="mb-12 text-md">{description}</p>}
        <div className="-mx-2 mb-8 grid grid-cols-1 items-start gap-x-8 gap-y-6 sm:mx-0 sm:grid-cols-2">
          <OrderSummary>
            <OrderItem loading={isBusy}>
              <>
                <span className="font-accent text-lg text-cello-500">
                  {toFriendlyInterval(priceLineItem?.description as string)}
                </span>
                <span className="text-base font-semibold text-cello-500">
                  {priceLineItem?.amountDisplay}
                </span>
              </>
            </OrderItem>

            <div className="flex flex-row py-6">
              <CheckIcon className="size-6 mr-2 text-geebung-500" />
              <span>Exclusive access to first run of Osteoboost</span>
            </div>

            <div className="flex flex-row py-6">
              <CheckIcon className="size-6 mr-2 text-geebung-500" />
              <span>1-year free membership to Wellen by Osteoboost</span>
            </div>

            <div className="flex flex-row py-6">
              <CheckIcon className="size-6 mr-2 text-geebung-500" />
              <span>Extended 2-year warranty included</span>
            </div>

            <div className="flex flex-row py-6">
              <CheckIcon className="size-6 mr-2 text-geebung-500" />
              <span>Complimentary shipping</span>
            </div>

            {/* ========================== */}
            {/* Total Due Today component  */}
            {/* ========================== */}
            <li className="flex items-center justify-between py-4">
              {isBusy ? (
                <LoadingSkeleton containerClassName="grow" width="100%" />
              ) : (
                <>
                  <span className="font-accent text-lg text-cello-500">
                    Total due today
                  </span>
                  <span className="text-base font-semibold text-cello-500">
                    {totalDisplay}
                  </span>
                </>
              )}
            </li>
          </OrderSummary>

          {/* ====================== */}
          {/* STRIPE FORM RIGHT SIDE */}
          {/* ====================== */}
          <form
            ref={formRef}
            className="flex flex-col gap-y-6 sm:gap-y-8"
            onSubmit={onSubmit}
          >
            {/* ======================= */}
            {/* Stripe Credit Card Form */}
            {/* ======================= */}
            <PaymentElement
              className="w-full"
              onReady={() => {
                setStripeReady(true);
              }}
              options={stripePaymentElementOptions}
            />
            {/* ============= */}
            {/* Payment Terms */}
            {/* ============= */}
            {stripeReady && (
              <p className="text-sm text-cello-400">
                {isBusy ? (
                  <LoadingSkeleton
                    containerClassName="grow"
                    count={3}
                    width="100%"
                  />
                ) : (
                  <>
                    Cancel your order at any time prior to shipment for a full
                    refund. Once received, you may return the device within 30
                    days for a full refund, provided it is unaltered and
                    undamaged. Shipping date is estimated and not guaranteed.
                    All prices are in USD. This order is subject to our{" "}
                    <a
                      className="underline"
                      href="https://www.bonehealthtech.com/terms-of-sale/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Terms of Sale
                    </a>{" "}
                    and{" "}
                    <a
                      className="underline"
                      href="https://www.bonehealthtech.com/osteoboost-limited-warranty-2/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Limited Warranty Policy
                    </a>
                    .
                  </>
                )}
              </p>
            )}
            <input className="absolute hidden" type="submit" />

            {/* ============= */}
            {/* Submit Button */}
            {/* ============= */}
            <div className="-mx-2 flex flex-col gap-y-2 sm:mx-0 sm:flex-row-reverse sm:justify-between">
              <Button
                className="w-full text-center sm:w-32"
                disabled={isLoading || isSubmitting || createLoading}
                type="submit"
              >
                {isLoading || isSubmitting || createLoading ? (
                  <LoadingSpinner />
                ) : (
                  submitButtonText
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

type OrderSumamryProps = {
  children: React.ReactNode;
};
const OrderSummary: React.FC<OrderSumamryProps> = (props) => {
  return (
    <div className="flex flex-col items-stretch overflow-hidden rounded-2xl bg-white shadow-md">
      <ul className="divide-y divide-cararra-500 px-6 pb-2 sm:px-8 sm:pb-4">
        {props.children}
      </ul>
    </div>
  );
};

type OrderItemProps = {
  loading: boolean;
  children: React.ReactNode;
};

const OrderItem: React.FC<OrderItemProps> = ({ children, loading = false }) => {
  return (
    // flex items-center py-4 text-base font-semibold
    <li className="flex items-center justify-between py-4">
      {loading ? (
        <LoadingSkeleton containerClassName="grow" width="100%" />
      ) : (
        children
      )}
    </li>
  );
};
