import { useApolloClient } from "@apollo/client";
import { AppBar, AppBarVariant, LogoVariant } from "@getwellen/valesco";
import { ErrorBoundary } from "components/error-boundary";
import IntakeProgress from "components/intake-progress";
import { useAuth } from "contexts/AuthContext";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import React, { memo, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type PublicProps = {
  fullHeight?: boolean;
  showProgress?: boolean;
  showAppBar?: boolean;
  disableTopPadding?: boolean;
  logoHref?: string;
  loginRedirect?: string;
  logoutRedirect?: string;
  // In almost every case, the variable will not be defined.
  // The exception is the Osteoboost order flow which will specify a non-Wellen logo
  logoVariant?: LogoVariant;
  children?: React.ReactNode;
};

const defaults = {
  logoHref: "https://www.getwellen.com/",
  loginRedirect: "/login"
};

const Public: React.FC<PublicProps> = ({
  fullHeight = false,
  showProgress = false,
  showAppBar = true,
  disableTopPadding = false,
  logoHref = defaults.logoHref,
  loginRedirect = defaults.loginRedirect,
  logoutRedirect,
  logoVariant,
  children
}) => {
  const { isAuthenticated, logout } = useAuth();
  const showLogin =
    !window.location.pathname.includes("login") && !isAuthenticated;
  const showLogout = isAuthenticated;
  const client = useApolloClient();

  const handleLogout = useCallback(() => {
    logout({
      returnTo: logoutRedirect,
      apolloClient: client
    });
  }, []);

  return (
    <div
      className={twMerge(
        "flex flex-col bg-cararra-100",
        fullHeight ? "min-h-screen" : ""
      )}
    >
      <ErrorBoundary>
        <header>
          {showAppBar && (
            <AppBar
              logoHref={logoHref}
              logoTarget="_blank"
              logoVariant={logoVariant}
              rightContent={
                <>
                  {showLogin && (
                    <span>
                      <span className="hidden md:inline">
                        Already have an account?&nbsp;
                      </span>
                      <a
                        className="font-semibold underline"
                        href={loginRedirect}
                      >
                        Login
                      </a>
                    </span>
                  )}
                  {showLogout && (
                    <span>
                      <button
                        className="font-semibold underline"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </span>
                  )}
                </>
              }
              variant={AppBarVariant.Dark}
            />
          )}

          {showProgress && (
            <IntakeProgress className={showAppBar ? "pt-14 md:pt-0" : ""} />
          )}
        </header>

        <main
          className={twMerge(
            "h-full",
            !showProgress && !disableTopPadding ? "pt-14 md:pt-0" : ""
          )}
        >
          {children || <Outlet />}
        </main>
      </ErrorBoundary>
    </div>
  );
};

export default memo(Public);
