import { Spinner } from "@getwellen/valesco";
import { useOsteoboostOrder } from "contexts/OsteoboostOrderContext";
import { PaymentProvider } from "contexts/PaymentContext";
import { useScrollToTop } from "hooks/useScrollToTop";
import LoginPage from "pages/auth/LoginPage";
import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BillingAddressForm } from "./BillingAddressForm";
import { FormStep } from "./FormStep";
import OrderAuthCallback from "./OrderAuthCallback";
import { OrderComplete } from "./OrderComplete";
import OrderCreateAccount from "./OrderCreateAccount";
import { OrderPaymentPage } from "./OrderPaymentPage";
import { PatientForm } from "./PatientForm";
import { PrescriberForm } from "./PrescriberForm";
import { PrescriptionForm } from "./PrescriptionForm";
import { ShippingAddressForm } from "./ShippingAddresForm";
import { SizingForm } from "./SizingForm";

export const OsteoboostBaseRoute = "/order";

export enum OsteoboostOrderSlug {
  Root = "",
  Login = "login",
  Signup = "signup",
  AuthCallback = "authCallback",
  Payment = "payment",
  Patient = "patient",
  Sizing = "sizing",
  Shipping = "shipping",
  Billing = "billing",
  Prescription = "prescription",
  Prescriber = "prescriber",
  Complete = "complete"
}

const formOrder = [
  OsteoboostOrderSlug.Patient,
  OsteoboostOrderSlug.Sizing,
  OsteoboostOrderSlug.Shipping,
  OsteoboostOrderSlug.Billing,
  OsteoboostOrderSlug.Prescription,
  OsteoboostOrderSlug.Prescriber
];
const formSteps = formOrder.length;
export const orderRoute = (slug: string) =>
  slug.length ? `/order/${slug}` : `/order`;

const OsteoboostOrderPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useOsteoboostOrder();
  useScrollToTop();

  if (isLoading) {
    return (
      <div className="mt-[75%] flex items-center justify-center md:mt-[33%]">
        <Spinner className="fill-geebung-500" />
      </div>
    );
  }

  let CurrentPage = null;
  switch (slug) {
    // LOGIN FORM
    case OsteoboostOrderSlug.Login: {
      CurrentPage = (
        <LoginPage
          isOsteoboost={true}
          returnTo={orderRoute(OsteoboostOrderSlug.Root)}
          signupRedirect={orderRoute(OsteoboostOrderSlug.Signup)}
        />
      );
      break;
    }
    // SIGNUP FORM
    case OsteoboostOrderSlug.Signup: {
      CurrentPage = (
        <div className="h-full p-4 pt-16 md:m-auto md:max-w-md md:pt-32">
          <OrderCreateAccount
            loginReirect={orderRoute(OsteoboostOrderSlug.Login)}
            returnTo={orderRoute(OsteoboostOrderSlug.Payment)}
            subtitle="Get started with your Osteoboost pre-order."
            title="Create your Osteoboost Account"
          />
        </div>
      );
      break;
    }
    // PAYMENT FORM
    case OsteoboostOrderSlug.Payment: {
      CurrentPage = (
        <PaymentProvider>
          <div className="h-full p-4 pt-16 md:m-auto mx-auto w-auto md:my-6 md:max-w-4xl md:pt-32">
            <OrderPaymentPage
              description="Osteoboost is expected to ship to Founder’s Program members in 3-4 months. In the next step, we’ll gather your prescription information."
              navigateTo={orderRoute(OsteoboostOrderSlug.Patient)}
              title="Pay now to pre-order Osteoboost"
            />
          </div>
        </PaymentProvider>
      );
      break;
    }
    // MULTISTEP FORM
    case OsteoboostOrderSlug.Patient: {
      CurrentPage = (
        <FormStep
          description="Please provide your details to complete your pre-order."
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Sizing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Patient) }
            });
          }}
          showBack={false}
          step={1}
          title="Enter patient information"
          total={formSteps}
        >
          {(props) => <PatientForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Sizing: {
      const description = `To ensure you order the correct size, measure the
circumference of your hips at the widest point. Osteoboost is designed to fit
snugly over a thin layer of clothing to ensure effective vibration therapy.
Other sizes are not yet available.`;

      CurrentPage = (
        <FormStep
          description={description}
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Patient), {
              state: { from: orderRoute(OsteoboostOrderSlug.Sizing) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Shipping), {
              state: { from: orderRoute(OsteoboostOrderSlug.Sizing) }
            });
          }}
          step={2}
          title="Select your Osteoboost size"
          total={formSteps}
        >
          {(props) => <SizingForm description="" {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Shipping: {
      CurrentPage = (
        <FormStep
          description="Provide your shipping address below. First-run Osteoboost devices are currently estimated to ship in 3-4 months. If the shipping date changes, we will notify you."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Sizing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Shipping) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Billing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Shipping) }
            });
          }}
          step={3}
          title="Enter shipping address"
          total={formSteps}
        >
          {(props) => <ShippingAddressForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Billing: {
      CurrentPage = (
        <FormStep
          description="Verify your billing address to complete your preorder."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Shipping), {
              state: { from: orderRoute(OsteoboostOrderSlug.Billing) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescription), {
              state: { from: orderRoute(OsteoboostOrderSlug.Billing) }
            });
          }}
          step={4}
          subtitle=""
          title="Enter billing address"
          total={formSteps}
        >
          {(props) => <BillingAddressForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Prescription: {
      CurrentPage = (
        <FormStep
          description="Prescriptions are required before your Osteoboost device will ship. We offer two options for submitting a prescription. Please choose one."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Billing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescription) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescriber), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescription) }
            });
          }}
          step={5}
          subtitle=""
          title="Your prescription"
          total={formSteps}
        >
          {(props) => <PrescriptionForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Prescriber: {
      CurrentPage = (
        <FormStep
          description="Provide your prescriber’s details to complete the order process. Once your prescription is received, our pharmacy will use the information below to verify your prescription. Osteoboost prescriptions may be written by physicians, and in some states by nurse practitioners or other healthcare providers."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescription), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescriber) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Complete), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescriber) }
            });
          }}
          step={6}
          subtitle=""
          title="Enter prescription information"
          total={formSteps}
        >
          {(props) => <PrescriberForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Complete: {
      CurrentPage = <OrderComplete />;
      break;
    }
    case OsteoboostOrderSlug.AuthCallback: {
      CurrentPage = <OrderAuthCallback />;
      break;
    }
    default: {
      console.error(`Unknown route for /orders = ${slug}`);
      navigate(orderRoute(OsteoboostOrderSlug.Root));
      break;
    }
  }

  return CurrentPage;
};

export default memo(OsteoboostOrderPage);
