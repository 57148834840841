import { Button } from "@getwellen/valesco";
import {
  OrderDetails,
  OrderNumber,
  OrderTitle
} from "components/order-complete";
import { OrderNextSteps } from "components/order-complete/OrderNextSteps";
import { useCurrentUser } from "contexts/CurrentUserContext";
import {
  PrescriptionKey,
  PrescriptionTypeKey,
  sizeRename,
  StripeCardTypeKey,
  StripeLast4Key,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { Prescription, Product } from "graphql/rails-api";
import LoadingPage from "pages/LoadingPage";
import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const PROMO_CODE = "OSTEOBOOSTFP";

const getPrescriptionNextSteps = (prescriptionType: Prescription) => {
  switch (prescriptionType) {
    case Prescription.PrescriberWillContact:
      return {
        title: "Submit your prescription",
        children: (
          <>
            <span className="block">
              If your doctor has not yet submitted a prescription, please ask
              your doctor to call, fax, or e-prescribe your prescription to
              HealthWarehouse, our pharmacy partner based in Kentucky, USA.
            </span>
            <br />
            <span className="block">
              Phone:{" "}
              <a
                className="text-cello-500 font-semibold underline"
                href="tel:800-748-7001"
              >
                800-748-7001
              </a>
              <br />
              Fax:{" "}
              <a
                className="text-cello-500 font-semibold underline"
                href="tel:888-870-2808"
              >
                888-870-2808
              </a>
            </span>
          </>
        )
      };
    case Prescription.HwWillContact:
      return {
        title: "We will contact your doctor",
        children: (
          <>
            <span className="block">
              Using the information you provided, we will contact your doctor to
              obtain a new prescription.
            </span>
          </>
        )
      };
    case Prescription.UserWillContact:
      return {
        title: "Mail in your prescription",
        children: (
          <>
            <span className="block">
              If you have not yet mailed your prescription, please mail it to
              HealthWarehouse, our pharmacy partner based in Kentucky, USA.
            </span>
            <br />
            <span className="block">HealthWarehouse.com, Inc.</span>
            <span className="block">Attn: Pharmacy</span>
            <span className="block">7107 Industrial Rd.</span>
            <span className="block">Florence, KY 41042</span>
          </>
        )
      };
    default:
      return { title: "", children: null };
  }
};

const getExlusiveBenefits = (
  navigate: NavigateFunction,
  existingUser = false
) => {
  return {
    title: "Enjoy your exclusive benefits",
    children: (
      <div className="grid grid-cols-1 gap-8">
        {existingUser ? (
          <span className="block">
            As a Founder&apos;s Program member, you’re guaranteed a first spot
            in line unde for Osteoboost. Plus, you receive a 1-year free
            membership to Wellen by Osteoboost. If you&apos;re already a Wellen
            member, please contact customer support at{" "}
            <a
              className="font-semibold underline text-cello-500"
              href={`mailto:support@osteoboost.com`}
              rel="noreferrer"
              target="_blank"
            >
              support@osteoboost.com
            </a>{" "}
            to activate your free membership today.
          </span>
        ) : (
          <span className="block">
            As a Founder&apos;s Program member, you’re guaranteed a first spot
            in line for Osteoboost. Plus, you receive a 1-year free membership
            to Wellen by Osteoboost. Use coupon code{" "}
            <strong className="text-cello-700">{PROMO_CODE}</strong> at checkout
            on{" "}
            <a
              className="font-semibold underline text-cello-500"
              href={`/intake?promo_code=${PROMO_CODE}`}
            >
              getwellen.com
            </a>{" "}
            to claim your membership today.{" "}
          </span>
        )}
        <Button
          action="primary"
          className="w-full py-8 sm:py-0"
          onClick={() => {
            if (existingUser) {
              window.location.assign("mailto:support@osteoboost.com");
            } else {
              navigate(`/intake?promo_code=${PROMO_CODE}`, { replace: false });
            }
          }}
          variant="bold"
        >
          {existingUser
            ? "Contact customer support"
            : "Claim my 1-year free membership"}
        </Button>
      </div>
    )
  };
};

export const OrderComplete = () => {
  const { isLoading, order, products, refetchOrder } = useOsteoboostOrder();
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const existingUser = currentUser
    ? currentUser.isOnboarded && currentUser.isSubscribed
    : false;
  const orderNumber = order.orderNumber;
  const orderDate = new Date(order.orderDate || "").toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric"
    }
  );

  // Product information
  const orderProduct = products.find(
    (x) => x.id === order.sizing.id
  ) as Product;
  const shippingAddress = order.shippingAddress;
  const billingAddress = order.billingAddress;

  // Payment information
  const paymentLast4 = order[StripeLast4Key];
  const paymentCardType = order[StripeCardTypeKey];

  // Refetch the order only once on load
  useEffect(() => {
    refetchOrder();
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  const prescriptionType = order[PrescriptionKey][
    PrescriptionTypeKey
  ] as Prescription;
  const prescriptionNextSteps = getPrescriptionNextSteps(prescriptionType);
  const exclusiveBenefits = getExlusiveBenefits(navigate, existingUser);

  const orderDetails = [
    <div key="items-summary">
      <span className="text-base font-heading text-cello-500 font-semibold">
        Osteoboost Founder&apos;s Program
      </span>
      <ul className="list-disc list-inside">
        <li>{`Osteoboost${orderProduct ? `, Size ${sizeRename[orderProduct.size]} | ${orderProduct.name}` : ""}`}</li>
        <li>1-year free of Wellen by Osteoboost</li>
        <li>2-year limited warranty</li>
      </ul>
    </div>,
    <div key="shipping-address">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Shipping address
      </span>
      <div>
        <span className="block">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</span>
        <span className="block">{`${shippingAddress.address1}${shippingAddress.address2 ? `, ${shippingAddress.address2}` : ""}`}</span>
        <span className="block">{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipcode}`}</span>
      </div>
    </div>,
    <div key="billing-address">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Billing address
      </span>
      <div>
        <span className="block">{`${billingAddress.firstName} ${billingAddress.lastName}`}</span>
        <span className="block">{`${billingAddress.address1}${billingAddress.address2 ? `, ${billingAddress.address2}` : ""}`}</span>
        <span className="block">{`${billingAddress.city}, ${billingAddress.state} ${billingAddress.zipcode}`}</span>
      </div>
    </div>,
    <div key="payment-method">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Payment method
      </span>
      <div>
        {paymentCardType && paymentLast4 ? (
          <span className="block">
            {formatPaymentCardType(paymentCardType)} ending in {paymentLast4}
          </span>
        ) : null}
        <span className="block font-bold">
          {orderProduct ? `$${orderProduct.price}` : ""}
        </span>
      </div>
    </div>
  ];

  const nextSteps = [
    prescriptionNextSteps,
    {
      title: "Shipping information",
      children: (
        <>
          We will ship your Osteoboost as soon as it is in stock. While we
          estimate first-run devices to ship in 3-4 months, please note that we
          cannot guarantee a specific date. We will keep you updated.
        </>
      )
    },
    exclusiveBenefits
  ];
  return (
    <div className="min-h-screen w-full px-4 pb-24 pt-16 md:m-auto md:mt-10 md:pb-72 md:pt-12 md:w-[750px]">
      <div className="grid grid-col-1 gap-[4rem]">
        <div className="grid grid-col-1 gap-8">
          <OrderTitle />
          <OrderNumber
            orderDate={orderDate || ""}
            orderNumber={String(orderNumber)}
          />
          <div className="h-[1px] bg-lynch-200"></div>
          <OrderDetails>{orderDetails}</OrderDetails>
          <OrderNextSteps steps={nextSteps} />
        </div>
        <div className="grid grid-col-1 gap-8">
          <h1 className="font-display text-4xl md:text-5xl">
            Other important information
          </h1>
          <div className="grid grid-col-1 gap-4">
            <h3 className="font-display text-2xl">
              Refund and warranty information
            </h3>
            <p>
              {" "}
              You may cancel your order at any time before shipment for a full
              refund. Once received, you can return your device within 30 days
              for a full refund, provided it is unaltered and undamaged. Your
              device also comes with an extended 2-year limited warranty.{" "}
            </p>
          </div>
          <div className="grid grid-col-1 gap-4">
            <h3 className="font-display text-2xl">Need assistance?</h3>
            <p>
              If you need to cancel your order or update your personal
              information, please contact us at{` `}
              <a
                className="font-semibold underline"
                href="mailto:support@osteoboost.com"
                rel="noreferrer"
                target="_blank"
              >
                support@osteoboost.com
              </a>{" "}
              or call us at 1-833-GO-OSTEO.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// This function will take a card type string and format it into a dispalable string
//
// E.g. "visa" -> "Visa"
// E.g. "american express" -> "American Express"
const formatPaymentCardType = (cardType: string) => {
  return cardType
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
