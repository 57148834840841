import { Button, SocialButtonType, TextInput } from "@getwellen/valesco";
import { Auth0Result } from "auth0-js";
import { PasswordInput } from "components/form/password-input/PasswordInput";
import { SocialButtons } from "components/login-sign-up/SocialButtons";
import { useAuth } from "contexts/AuthContext";
import { useCustomerIo } from "contexts/CustomerIoContext";
import { useAuthError } from "hooks/useAuthError";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import React, { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import {
  errorHelpText,
  validEmailPattern,
  validPasswordRegex
} from "utils/forms";

import { usePostLogin } from "./usePostLogin";
import { useSocialLogin } from "./useSocialLogin";

type LoginPageProps = {
  // Defines where to go after successfully logging in
  returnTo?: string;
  signupRedirect: string;
  isOsteoboost?: boolean;
};

const signupRedirectDefault = `/intake`;

const LoginPage: React.FC<LoginPageProps> = ({
  signupRedirect = signupRedirectDefault,
  isOsteoboost = false
}) => {
  const location = useLocation();
  const {
    control,
    formState: { errors, isValid },
    getValues,
    setError,
    reset
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      phone: ""
    }
  });

  const { email: customerIoEmail, reset: resetCustomerIo } = useCustomerIo();

  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { onLogin } = usePostLogin(isOsteoboost);

  const handleLogin = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (isValid) {
      setIsLoading(true);

      login(getValues().email, getValues().password)
        .then((result) => {
          const authResult = result as Auth0Result;
          onLogin(authResult);
        })
        .catch((err) => {
          setError("password", {
            type: "custom",
            message: err?.description || "Invalid login"
          });
          setIsLoading(false);
        });
    }
  };

  useAuthError();

  useEffect(() => {
    if (customerIoEmail) {
      reset({
        ...getValues(),
        email: customerIoEmail
      });

      resetCustomerIo();
    }
  }, [customerIoEmail]);

  const { onSocialLoginClick } = useSocialLogin();

  const socialButtons = [SocialButtonType.Google, SocialButtonType.Facebook];
  return (
    <div className="h-screen p-4 pt-16 md:m-auto md:max-w-md md:pt-32">
      <h1 className="mb-6 font-display text-4xl">Login</h1>
      {socialButtons.length > 0 && (
        <>
          <SocialButtons
            buttons={socialButtons}
            onClick={(buttonType) => {
              onSocialLoginClick(
                buttonType,
                isOsteoboost
                  ? window.location.origin +
                      orderRoute(OsteoboostOrderSlug.AuthCallback)
                  : undefined
              );
            }}
          />
          <div className="relative mb-6 flex items-center">
            <div className="grow border-t border-gray-400"></div>
            <span className="mx-3 shrink text-xs text-cello-400">OR</span>
            <div className="grow border-t border-gray-400"></div>
          </div>
        </>
      )}

      <form onSubmit={handleLogin}>
        <div className="mb-6">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.email}
                helpText={errorHelpText(errors.email)}
                label="Email address"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true, pattern: validEmailPattern }}
          />
        </div>
        <div className="mb-2">
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value } }) => (
              <PasswordInput
                autoComplete="current-password"
                error={errors.password}
                label="Password"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long"
              },
              validate: (value) =>
                !!new RegExp(validPasswordRegex).test(value) ||
                "Password must contain lower case letters (a-z), upper case letters (A-Z), and numbers (i.e 0-9)"
            }}
          />
        </div>
        <Link
          className="font-xs mb-6 block font-semibold underline"
          to="/resetPassword"
        >
          Forgot password?
        </Link>
        <Button
          action="primary"
          className="mb-6 w-full"
          disabled={isLoading || !isValid}
          onClick={handleLogin}
          size="large"
          type="submit"
        >
          Login
        </Button>
      </form>
      <p>
        Don&apos;t have an account yet?{" "}
        <Link className="font-semibold underline" to={signupRedirect}>
          Signup here.
        </Link>
      </p>
    </div>
  );
};

export default memo(LoginPage);
